/**
* @Author: Ajay
* @Date:   27-May-2017 11:05 +05:30
* @Project: Ezyprocure
* @Filename: buyer.good-receipt-note.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:59 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerGoodReceiptNoteController', BuyerGoodReceiptNoteController);

  BuyerGoodReceiptNoteController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog','pagingParams', 'BuyerDataService', 'NotificationService', 'UTCDateService', 'BuyerBusinessService', 'PurchaseOrderService', 'BuyerSupplierMappingService', 'ItemGroupService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerBusinessUserMapService', 'BuyerBuyNowService', 'CSVExportService', 'BuyerReceivingOrderService', 'EXPORT_CONSTANTS','UtilService'];

  function BuyerGoodReceiptNoteController($scope, $rootScope, $q, $filter, $state, $stateParams, toastr, $mdDialog,pagingParams, BuyerDataService, NotificationService, UTCDateService, BuyerBusinessService, PurchaseOrderService, BuyerSupplierMappingService, ItemGroupService, GLOBAL_CONSTANTS, paginationConstants, BuyerBusinessUserMapService, BuyerBuyNowService, CSVExportService, BuyerReceivingOrderService, EXPORT_CONSTANTS,UtilService) {

    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.prevSelectedOutletId= 0;
    vm.loadItemGroups = loadItemGroups;
    vm.checkAll = checkAll;
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.receivingStatuses = GLOBAL_CONSTANTS.RECEIVING_STATUSES;
    vm.limitOptions = paginationConstants.limitOptionsWithAll;
    //Header
    vm.goodsReceiptNoticeReport = EXPORT_CONSTANTS.CSV.BuyerGoodsReceiptNoticeReport;
    //Function Declaration
    vm.getGoodsReceiptNote = getGoodsReceiptNote;
    vm.getSelectedBusinessOutlet = getSelectedBusinessOutlet;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.mergeGoodsRecivesWithSamePO = mergeGoodsRecivesWithSamePO;
  vm.checkOnce=checkOnce;
  vm.cancel=cancel;
  vm.downloadSelected=downloadSelected;
  vm.openModelForCSV=openModelForCSV;
  vm.downloadAll=downloadAll;
  vm.validateDateRange = validateDateRange;
    //filter reset
    vm.resetFilters = resetFilters;
    vm.filter = {
        receivingStatus: vm.receivingStatuses[0],
        listView: vm.listViews[0]
    };
    
    vm.filter.grnFromDate = vm.todaysDate;
    vm.filter.grnToDate = vm.todaysDate;            
    $scope.dateLocale = {
      formatDate: function(date) {
        var m = moment(date);
        return m.isValid() ? m.format('DD/MM/YYYY') : '';
      }
    };             
    vm.filter.deliveryFromDate = null;
    vm.filter.deliveryToDate = null;
    vm.filter.poFromDate = null;
    vm.filter.poToDate = null;            
    vm.selectAlll = selectAlll;
    vm.deselectAll = deselectAll;
    vm.navigateToConsolidatedView = navigateToConsolidatedView;
    vm.grnCSVHeader = EXPORT_CONSTANTS.CSV.grnCSVHeader;
    vm.loadAllIfSpecificFiltersFilled = loadAllIfSpecificFiltersFilled;
    vm.init = init();

    function init() {
        vm.filter.businessOutletId = '';
        if(vm.filter.businessOutletId) {
            vm.getAllBuyerBusinessOutlets();
          }
          if(vm.filter.supplierId) {
            vm.getAllAllocatedSuppliersForBusiness();
          }

          if(vm.filter.itemGroupId) {
            vm.loadItemGroups();
          }
          vm.loadAll();
    }
    //Getting CSV DATA
    function getGoodsReceiptNote() {
      CSVExportService.getBuyerGoodsReceiptNoteReportCSV()
          .then(function (response) {
            console.log(response);
              return response;
          })
          .then(function (arrayBuffer) {
              // Convert arrayBuffer to Blob
              const blob = new Blob([arrayBuffer], { type: 'text/csv' });
  
              // Create a Blob URL and initiate download
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'purchase_orders.csv';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
          })
          .catch(function (error) {
              console.error('Error fetching or processing CSV data:', error);
          });
  }
  
  function checkOnce() {
    vm.selectAll = _.every(vm.goodReceivingNotes, 'selected');
  }
  
  
    function openModelForCSV() {
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/grn-pop-csv.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function cancel() {
      $mdDialog.cancel();
    }
    /**
    * [downloadSelected() To download selected POs as CSV]
    */
    function downloadSelected(){
      var deferred = $q.defer();      
      var selectedItems = _.filter(vm.goodReceivingNotes, function (item) {
        return item.selected;
      })
      if (selectedItems.length === 0) {
        NotificationService.simpleErrorToast({
          title: 'global.goodReceiptNotes',
          message: 'alertMessage.PLEASE_SELECT_PO'
        });
        return deferred.promise;
      }else{
          vm.cancel();
          return CSVExportService.getBuyerGoodsReceiptNoteReportCSV(selectedItems, 'buyer')
      }
    }
    /**
    * [downloadAll() To download all POs listed as CSV]
    */
    function downloadAll() {
      var goodReceiptNote = {
        page: vm.query.page - 1,
        size: vm.totalItems,
        sort: sort(),
        invoiceStatus: vm.filter.receivingStatus !== "ALL" ? vm.filter.receivingStatus : '',
        businessOutletId: !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId,
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.poFromDate)),
        poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.poToDate)),
        deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.deliveryFromDate)),
        deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.deliveryToDate)),
        grnFromDate: !vm.filter.grnFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.grnFromDate)),
        grnToDate: !vm.filter.grnToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.grnToDate)),
        supplierId: !vm.filter.supplierId ? '' : vm.filter.supplierId,
        itemGroupId: !vm.filter.itemGroupId ? '' : vm.filter.itemGroupId,
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber
      };
      BuyerBusinessUserMapService.getGoodReceiptStatusAllCSV(goodReceiptNote)
        .then(function (response) {
          console.log(response);
          vm.cancel();
          return response;
        })
        .then(function (arrayBuffer) {
          // Convert arrayBuffer to Blob
          const blob = new Blob([arrayBuffer], { type: 'text/csv' });
          // Create a Blob URL and initiate download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'Goods_Receipt_Note_Report.csv';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
        .catch(function (error) {
          console.error('Error fetching or processing CSV data:', error);
        });
    }
    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
    function getAllBuyerBusinessOutlets() {
      if(vm.businessOutlets)
        return false;
      vm.businessOutlets = null;
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
      then(function(response){
        vm.businessOutlets = response;
        //if only one business outlet set it as default
        if(vm.businessOutlets.length === 1) {
          vm.businessId = vm.businessOutlets[0].id;
        }
      },function(error){
      });
    }

    /**
    * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
    */
    function getAllAllocatedSuppliersForBusiness() {
      if(vm.supplierList && vm.prevSelectedOutletId === vm.filter.businessOutletId)
        return false;
      vm.prevSelectedOutletId = vm.filter.businessOutletId;
      var params = {'businessId': !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
        //if only one allocated supplier set it as default
        if(vm.supplierList.length === 1) {
          vm.filter.supplierId = vm.supplierList[0].id;
        }
      },function(error){
      });
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function getSelectedBusinessOutlet() {
     vm.selectedOutlet =  _.filter(vm.businessOutlets, function(outlet){
        return vm.filter.businessOutletId == outlet.id;
      })
    }

    /**
    * [loadAll Gets all GRN]
    */
    function loadAll() {
      var loadAllIfSpecificFiltersFilled = vm.loadAllIfSpecificFiltersFilled();
      if (loadAllIfSpecificFiltersFilled){
      if(vm.filter.listView === "Individual"){
        PurchaseOrderService.getGRNDetails({
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort(),
          invoiceStatus: vm.filter.receivingStatus !== "ALL" ? vm.filter.receivingStatus : '' ,
          businessOutletId: !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId,
          businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
          poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.poFromDate)),
          poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.poToDate)),
          deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.deliveryFromDate)),
          deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.deliveryToDate)),
          grnFromDate : !vm.filter.grnFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.grnFromDate)),
          grnToDate : !vm.filter.grnToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.grnToDate)),
          supplierId: !vm.filter.supplierId ? '': vm.filter.supplierId,
          itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
          poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber
        }).then(function(response){
          vm.goodReceivingNotes = response.data;
          vm.totalItems = response.headers('X-Total-Count');
        },function(error){
          NotificationService.error({
            title: 'Goods Receipt Notes Details',
            error: error
          });
        });
      }
    }
    }

    function validateDateRange(numberOfDays, title, filterAvailable) {
    var poDateSelected = UtilService.checkDateRangeExists(vm.filter.poFromDate, vm.filter.poToDate, filterAvailable);
    var deliveryDateSelected = UtilService.checkDateRangeExists(vm.filter.deliveryFromDate, vm.filter.deliveryToDate, filterAvailable);
    var grnDateSelected = UtilService.checkDateRangeExists(vm.filter.grnFromDate, vm.filter.grnToDate, filterAvailable);
    
    if (!poDateSelected && !deliveryDateSelected && !grnDateSelected) {
      toastr.error('Please select valid date ranges!', title);
      return false;
    }
    var poValid = UtilService.checkDateRangeValidity(vm.filter.poFromDate, vm.filter.poToDate, numberOfDays, title, poDateSelected);
    var deliveryValid = UtilService.checkDateRangeValidity(vm.filter.deliveryFromDate, vm.filter.deliveryToDate, numberOfDays, title, deliveryDateSelected);
    var grnValid = UtilService.checkDateRangeValidity(vm.filter.grnFromDate, vm.filter.grnToDate, numberOfDays, title, grnDateSelected);
    if ((poDateSelected && !poValid) ||(deliveryDateSelected && !deliveryValid) || (grnDateSelected && !grnValid)) {
      return false;
    }     
    return true;
  }
  
  
  
  /**
    * [selectAllPR select all Purchase Requests]
    * @return {[type]} [description]
    */
   function checkAll() {
    vm.selectAll ? vm.selectAll = true : vm.selectAll = false;console.log(vm.goodReceivingNotes);
    angular.forEach(vm.goodReceivingNotes, function(eachRequest) {
      console.log(eachRequest);
      eachRequest.selected = vm.selectAll;
    });
  }
      /**
   * mergeGoodsRecivesWithSamePO merges goods receving data wuth same po number
   */
  function mergeGoodsRecivesWithSamePO(){
    var grnList = [];
    var grnPONumberList = [];
    var grnRecevingStatus = [];
    var grnDeliveryDateList = [];
    grnList = _.filter(vm.goodReceivingNotes, function(eachRequest){
       if(eachRequest.selected==true){
         grnPONumberList.push(eachRequest.purchaseOrderNumber);
         grnDeliveryDateList.push(eachRequest.processOrder.deliveryDate);
         grnRecevingStatus.push(eachRequest.purchaseOrderStatus);
         return eachRequest;
       }
     });
     grnList = _.map(grnList, function(eachRequest){
      return {
        'deliveryDate':eachRequest.processOrder.deliveryDate,
        'purchaseOrderNumber': eachRequest.purchaseOrderNumber
      };
     })
    if(grnList.length > 1 ){
       var poNumberList =[];
       poNumberList = _.uniq(grnPONumberList, function(items){
        return items;
       });
       grnRecevingStatus = _.uniq(grnRecevingStatus, function(items){
         return items;
       })
       if(poNumberList.length == 1 && grnRecevingStatus.length == 1){
        BuyerReceivingOrderService.mergeGrnWithSamePo(grnList).
        then(function(response){
          NotificationService.success({
            title: 'alertMessage.MERGING_POS',
            message: 'alertMessage.MERGED_SUCCESSFULLY'
        });
        vm.loadAll();
        }, function(error){
          NotificationService.error({
            title: 'alertMessage.MERGING_POS',
            error: error
          });
        });

       }
       else {
         NotificationService.simpleErrorToast({
           title: 'alertMessage.MERGING_POS',
           message: 'alertMessage.PO_NUMBER_OR_RECEVING_STATUS_SHOULD_BE_SAME_FOR_ALL'
         });
       }
    }
    else{
       NotificationService.simpleErrorToast({
         title: 'alertMessage.MERGING_POS',
         message: 'alertMessage.ATLEAST_TWO_REQUIRED'
       });
    }
  }
   // filter reset
   function resetFilters() {
    vm.filter.poNumber = ''
    vm.filter.receivingStatus = vm.receivingStatuses[0]
    vm.filter.supplierId = '';
    vm.filter.itemGroupId = '';
    vm.filter.businessOutletId = '';
    vm.filter.listView = vm.listViews[0];
    vm.filter.poFromDate = null;
    vm.filter.poToDate = null
    vm.filter.deliveryFromDate = null;
    vm.filter.deliveryToDate = null;
    vm.filter.grnFromDate = vm.todaysDate;
    vm.filter.grnToDate = vm.todaysDate;
    vm.goodReceivingNotes = [];
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.loadAll();
}  

  function selectAlll(dropdown) {
    switch (dropdown) {
      case 'OUTLETS':
        var filteredArray = [];
        if (vm.businessOutletSearchTerm) {
          // Only select values in business outlets id if they match vm.businessOutletSearchTerm
          filteredArray = _.filter(vm.businessOutlets, function(item) {
            return _.includes(item.businessOutletName.toLowerCase(), vm.businessOutletSearchTerm.businessOutletName.toLowerCase());
          });
        }
        vm.filter.businessOutletId = _.map(filteredArray.length!==0?filteredArray:vm.businessOutlets, _.property('id'));
        break;
    }
  }

  function deselectAll(dropdown) {
    switch (dropdown) {
      case 'OUTLETS':
        vm.filter.businessOutletId = [];
        break;
    }            
  }

      //creates an object vm.purchaseOrderRequestDTO to use as a request in consolidated view
      function navigateToConsolidatedView(){
        vm.purchaseOrderRequestDTO={
          poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
          purchaseOrderStatus: vm.filter.poStatus ,
          invoiceStatus: '',
          invoiceNumber: '',
          businessOutletId: !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId ,
          businessOutletName: '' ,
          poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.poFromDate)),
          poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.poToDate)),
          deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.deliveryFromDate)),
          deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.deliveryToDate)),
          grnFromDate:!vm.filter.grnFromDate?'':vm.filter.grnFromDate,
          grnToDate:!vm.filter.grnToDate?'':vm.filter.grnToDate,
          query: !vm.filter.itemName ? '' : vm.filter.itemName,
          supplierId: !vm.filter.supplierId ? '': vm.filter.supplierId,
          itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
          debitNoteStatus: '',
          grnNumber: '',
          buyerName: '',
          creditNoteStatus: '',
          supplierVisibilityType: vm.filter.supplierVisibilityType ? vm.filter.supplierVisibilityType : '',
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort(),
          xeroExported: vm.filter.xeroExported ? vm.filter.xeroExported : '',
          module : 0
        };
        var canRedirect = PurchaseOrderService.setPurchaseRequestParams(vm.purchaseOrderRequestDTO);
        if(canRedirect == true){
          $state.go('buyer.reports.good-receipt-note.consolidated-details', {
          reload: false
        });
      }
      }

      function loadAllIfSpecificFiltersFilled() {
        var validationResult = false;
        var filtersToCheck = ['poNumber','itemGroupId', 'businessOutletId', 'supplierId'];
        var filterAvailable = UtilService.areSpecificFiltersFilled(filtersToCheck,vm.filter);
        var numberOfDays = GLOBAL_CONSTANTS.REPORTS_DATE_VALIDATION;
        var title = "Good Receipt Notes";      
        validationResult = validateDateRange(numberOfDays, title, filterAvailable);
        return validationResult;
      }
  }
})();
