/**
 * @Author: Mufsir
 * @Date:   18-May-2017 19:36 +05:30
 * @Project: Ezyprocure
 * @Filename: export-option.constants.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-12-02T16:42:41+05:30
 */



"use strict";
angular.module('ezyprocureApp')
.constant('EXPORT_CONSTANTS', {
  "CSV": {
    "eGiroFundTransferCSVHeader":['Sl_No','Payer','Payee', 'Payee Account Name', 'Payee Account Number', 'Payee Bic Code', 'Amount','Collection Reference Number','Payment Reference Number','eGiro initiated Date','Remittance Status','eGiro Descriptor','eGiro Status'],
    "axsPaymentIndividualHeader": ['Sl No', 'Transaction Id', 'Transaction Time Stamp', 'Supplier Name', 'Brand Name', 'Issuer', 'Issued Country', 'Issued Bank', 'Account Type', 'Invoice No', 'Invoice Amount', 'Buyer BSF Fee', 'Buyer BSF GST/VAT %', 'Buyer BSF Total', 'Supplier BSF Fee', 'Supplier BSF GST/VAT %', 'Supplier BSF Total', 'Payment Model', 'Buyer Paid Amount', 'Remittance Amount', 'Currency', 'GIRO Status'],
    "axsPaymentConsolidatedHeader": ['Sl No', 'Supplier Name', 'GIRO File Name', 'AXS Transaction Status', 'GIRO Transaction Status', 'Invoice Amount', 'Buyer BSF Fee', 'Buyer BSF GST/VAT %', 'Buyer BSF Total', 'Supplier BSF Fee', 'Supplier BSF GST/VAT %', 'Supplier BSF Total', 'Buyer Paid Amount', 'Remittance Amount', 'Currency', 'GIRO Status'],
    "blanketOrderItemsHeaders":['Blanket Order Number', 'Validity Start Date', 'Validity End Date', 'Item Code', 'Item Name', 'Quantity', 'UOM', 'Currency', 'Price', 'Remarks', 'Requested Quantity', 'Ordered Quantity'],
    "crossBorderConsentsCsv": ["SL No.", "Supplier", "Opt In Date", "Screenshot", "Opt In Status", "OPS Status"],
    "ax2PaymentCsvHeader": ['SL No', 'Partner Code', 'Payer Id', 'Payer Name', 'Payer UEN', 'Payer Email', 'Payee Name', 'Payee Email', 'Account Name', 'Account Number', 'Bank Name','Bank Code','Swift Code','Reference Number','Amount','Currency','Purpose', 'Access Status', 'Giro Type', 'Pay Type', 'Fate', 'Fate Remarks'],
    "SupplierManageItemsCSVHeader": ['Sl No', 'Item Code', 'Item Name', 'Item Group', 'UOM', 'Currency'],
    "GlCodeMappingCsvExportHeader": ['#', 'Purchase Order Type', 'Item Group', 'GL Code', 'GL Specific Item Group'],
    "ReorderLevelReport": ["businessOutletName","supplierType","supplierCompanyName","purchaseOrderType","itemCode","itemName","itemGroup","quantity","deliveryDate"],
    "UenConsolidatedCSVHeader":['UEN/PEPPOL ID','Volume Invoices Sent','Volume Invoices Received','Total'],
    "goodsReceiptNoteHeader": ['itemCode', 'itemName','uom', 'quantityPO', 'quantityAccepted', 'weightPO', 'weightAccepted', 'unitPrice', 'amountPO', 'amountAccepted', 'delivery_date_po', 'delivery_date_accepted', 'specific_request', 'remark', 'purchase_order_date', 'delivery_date', 'goods_receipt_date'],
    "goodsReceiptNoteHeaderSupplier": ['itemCode', 'itemName','itemGroup','uom', 'quantityPO', 'quantityAccepted', 'weightPO', 'weightAccepted', 'unitPrice', 'amountPO', 'amountAccepted', 'delivery_date_po', 'delivery_date_accepted', 'specific_request', 'remark', 'purchase_order_date', 'delivery_date', 'goods_receipt_date'],
    "purchaseOrderHeader": ['po_no', 'buyer code', 'business_outlet', 'supplier', 'order_date', 'supplier_item_code', 'item_name', 'gl_specific_item_groups','uom', 'quantity_required', 'quantity_supplier', 'weight', 'unit_price', 'amount_required', 'amount_supplier', 'delivery_date_required', 'delivery_date_supplier', 'specific_request', 'purchase_order_date', 'remark'],
    "supplierPOHeader": ['poNumber', 'businessOutlet', 'buyer', 'poDate', 'itemCode', 'itemName', 'uom', 'poQuantity', 'receivedQuantity', 'unitPrice', 'totalAmount', 'currency', 'receviedDate', 'specialRequest', 'remark', 'deliveryDate'],
    "invoiceHeader": ['ContactName', 'EmailAddress', 'POAddressLine1', 'POAddressLine2', 'POAddressLine3', 'POAddressLine4', 'POCity', 'PORegion', 'POPostalCode', 'POCountry', 'InvoiceNumber', 'Reference', 'InvoiceDate', 'DueDate', 'Total', 'InventoryItemCode', 'Description', 'Quantity', 'UnitAmount', 'Discount', 'AccountCode', 'TaxType', 'TaxAmount', 'TrackingName1', 'TrackingOption1', 'TrackingName2', 'TrackingOption2', 'Currency', 'BrandingTheme'],
    "geoInvoiceHeader": ['Document Code', 'Org No', 'Loc No', 'Purchaser No', 'Document Date', 'Account Code', 'Trade', 'Transportation', 'Ship To', 'Shipping Address 1', 'Shipping Address 2', 'Shipping Address 3', 'Shipping Address 4', 'Shipping Address Tel No', 'Shipping Address Fax No', 'Project Code', 'Tax Code', 'Tax Rate', 'Currency Code', 'Currency Rate', 'Trade Terms', 'Your Reference', 'Our Reference', 'Department Code', 'Remarks', 'Item No', 'Item Flag', 'Stock Code', 'Description', 'Qty', 'UOM', 'UOM Ratio', 'Stock Qty', 'Sales Price', 'Net Price', 'Item Remarks', 'A/C Code'],
    "quickBooksCSVHeader": ['Bill No', 'Supplier', 'Bill Date', 'Due Date', 'Terms', 'Mailing Address Line 1', 'Mailing Address Line 2', 'Mailing Address Line 3', 'Mailing Address City', 'Mailing Address Postal Code', 'Mailing Address Country', 'Mailing Address Country Subdivision Code', 'Memo', 'Global Tax Calculation', 'Expense Account', 'Expense Description', 'Expense Line Amount', 'Expense Billable Status', 'Expense Markup Percent', 'Expense Customer', 'Expense Class', 'Expense Tax Code', 'Expense Account Tax Amount', 'Product/Service', 'Product/Service Description', 'Product/Service Quantity', 'Product/Service Rate', 'Product/Service Amount', 'Product/Service Billable Status', 'Product/Service Tax Code', 'Product/Service Tax Amount', 'Product/Service Markup Percent', 'Billable Customer:Product/Service', 'Product/Service Class', 'Location', 'Currency Code', 'Exchange Rate'],
    "quickBooksItemsCSVHeader":['Bill No','Supplier','Bill Date','Due Date','Terms','Location','Memo','Account','Line Description','Line Amount','Line Tax Code','Line Tax Amount','Currency'],
    "xeroInvoiceHeader": ['ContactName', 'EmailAddress', 'POAddressLine1', 'POAddressLine2', 'POAddressLine3', 'POAddressLine4', 'POCity', 'PORegion', 'POPostalCode', 'POCountry', 'InvoiceNumber', 'Reference', 'InvoiceDate', 'DueDate', 'Total', 'InventoryItemCode', 'Description', 'Quantity', 'UnitAmount', 'AccountCode', 'TaxType', 'TaxAmount', 'TrackingName1', 'TrackingOption1', 'TrackingName2', 'TrackingOption2', 'Currency', 'BrandingTheme'],
    "creditNoteHeader": ['itemCode', 'itemName', 'uom', 'quantity', 'weight', 'unitPrice', 'amount'],
    "goodsReturnNoticeHeader": ['itemCode', 'itemName', 'uom', 'quantityAccepted', 'quantityReturned', 'weight', 'unitPrice', 'goodsReturnAmount', 'date_received', 'date_returned', 'remark'],
    "staffInterfaceHeader": ['PO Number', 'Supplier', 'Invoice Status', 'OCR Acceptance Status', 'Hardcopy Invoice OCR Result', 'E-Invoice OCR Result', 'Brand Name', 'Business Outlet', 'PO Date', 'PO Amount', 'GRN Amount', 'GRN Date', 'HardCopyInvoiceNumber','HardCopyInvoiceAmount','HardCopyInvoiceDate', 'Added GST/VAT', 'E Invoice Amount', 'E Invoice Number', 'OCR Response Reason', 'OCR Response Comment', 'Purchase order type'],
    "BuyerPurchaseOrderReport": ['S.No', 'PurchaseRequest Number', 'PurchaseRequest Date', 'Brand Name', 'Business Outlet', 'Supplier', 'PurchaseRequest Status'],
    "priceUpdateList": ['S.No', 'Item Name', 'Item Code', 'Item Group', 'UOM', 'Supplier', 'Business Outlet', 'Effective From', 'Effective To', 'Updated Date', 'Remark', 'Price', 'Updated Price'],
    "BuyerGoodReceiveDataOutletLevel": ['S.No', 'GRN Date Range', 'Outlet Code', 'Outlet Name', 'Item Name', 'Buyer Item Code', 'Supplier Item Code',  'Item Group', 'GL Group', 'GL Code', 'UOM', 'Price', 'Supplier', 'Qty', 'Amt'],
    "BuyerGoodReceiveData": ['S.No', 'GRN Date Range', 'Item Name', 'Buyer Item Code', 'Supplier Item Code',  'Item Group', 'GL Group', 'GL Code', 'UOM', 'Price', 'Supplier', 'Qty', 'Amt'],
    "BuyerGoodsReceiptNoticeReport": ['S.No', 'PurchaseOrder Number', 'PurchaseOrder Date', 'Business Outlet', 'Supplier', 'Receiving Status', 'Delivery Date'],
    "BuyerGoodsReturnNoticeReport": ['S No', 'Supplier', 'Purchase Order Number', 'GRN No', 'PO Date', 'Credit Note Status', 'Goods Return Date'],
    "BuyerInvoiceReport": ['S No', 'Invoice Number', 'Invoice Date', 'Supplier', 'Business Outlet', 'Invoice Status', 'PO Number', 'Invoice Amount', 'Purchase Order Date', 'Delivery Date', 'Goods Receipt Date'],
    "BuyerPriceUpdateReport": ['S No', 'Business Outlet', 'Supplier', 'Item Name', 'MOQ', 'Previous Price', 'Updated Price', 'Currency', 'Remark', 'Updated Date', 'Effective Start Date', 'Effective End Date', 'Status'],
    "SupplierPurchaseOrderReport": ['S.No', 'PurchaseOrder Number', 'PurchaseOrder Date','PurchaseOrder Type', 'Brand Name', 'Company Name', 'PurchaseOrder Status'],
    "SupplierGoodsReturnNoticeReport": ['S No',	'Buyer',	'Purchase Order Number', 'GRN No', 'PO Date', 'Credit Note Status', 'Goods Return Date'],
    "SupplierInvoiceReport": ['S No', 'Invoice Date', 'E-Invoice Number', 'PO Number', 'PO Date', 'Buyer Code', 'Buyer Name', 'Business Outlet Name', 'Item Code', 'Item Name', 'Item Group','Quantity', 'UOM', 'Unit Price', 'Amount', 'Delivery Date', 'Goods Receipt Date'],
    "SupplierPriceUpdateReport": ['S No', 'Buyer', 'Business Outlet', 'Item Name', 'MOQ', 'Previous Price',	'Updated Price', 'Currency', 'Remark', 'Buyer Remark', 'Updated Date', 'Effective Start Date', 'Effective End Date', 'Status'],
    "SupplierGoodsReceiptNoticeReport": ['S.No', 'PurchaseOrder Number', 'PurchaseOrder Date','Brand Name', 'Company Name', 'Receiving Status', 'Delivery Date'],
    "SupplierCreditNoteCSVReport": ['S.No', 'PurchaseOrder Number', 'Invoice Number', 'Created Date', 'BrandName', 'Customer Name'],
    "ConsolidatedGoodsReceiptNoteHeader": ['po_no','supplier', 'buyer', 'buyer code', 'business_outlet', 'po_status', 'Supplier Item Code', 'item_name','itemGroup','uom', 'PO_quantity', 'Accepted_quantity', 'PO_weight','Accepted_Weight', 'unit_price', 'PO_amount', 'accepted_amount', 'PO_delivery_date', 'Accepted_date', 'remark'],
    "BuyerConsolidatedGoodsReceiptNoteHeader": ['po_no','supplier', 'Contact Number', 'buyer', 'buyer code', 'business_outlet', 'po_status', 'Supplier Item Code', 'item_name', 'uom', 'PO_quantity', 'Accepted_quantity', 'PO_weight','Accepted_Weight', 'unit_price', 'PO_amount', 'accepted_amount', 'PO_delivery_date', 'Accepted_date', 'remark'],
    "TopPurchaseItemsReport": ['Current_Year_Rank', 'Item Code','Items', 'Item_Group','Business_Outlet','supplier' , 'Quantity', 'Value'],
    "TopSuppliersReport": [ 'S/No', 'Supplier', 'Value', 'From','To', 'Percentage'],
    "TopBuyersReport": ['S.No', 'Buyer_Name', 'Item_Code', 'Item_Name', 'Item_Group', 'Quantity','Value'],
    "ItemPricingReport": ['S_No', 'Item_Name','Buyer Item_Code', 'Supplier Item Code','UOM','Item_Group','Supplier', 'Business Outlet', 'Price', 'Currency'],
    "TotalPurchaseVolumeReport":['S No', 'GRN Date', 'GRN DOC#', 'Item Name', 'Item Group', 'Business Outlet', 'Supplier', 'Quantity'],
    "BoPurchaseReportCSV":['S No', 'Business Outlet', 'Item Name', 'Item Group', 'Quantity', 'Value'],
    "BuyerExportExpensesReport": ['S No', 'Business Outlet', 'Invoice Number', 'Supplier', 'Total', 'Invoice Date'],
    "PoSummaryHeader": ['S No', 'Requirement Number', 'PO Number', 'PO Date', 'PO Type', 'Business Outlet', 'Supplier', 'PO Status', 'GRN Number', 'Invoice Number'],
    "newItemLogHeader": ['S_No', 'Buyer Item Code', 'Supplier Item Code', 'Item Name', 'Item Group', 'Supplier', 'UOM', 'Price', 'Date Added', 'Approved By'],
    "PriceTrendsReport": ['S No', 'Business Outlet', 'Supplier', 'Buyer Item Code', 'Supplier Item Code', 'Item Name', 'Item Group', 'Price', 'From Date', 'To Date'],
    "TopPurchasedItemsReport": ['Current Year Rank', 'Items Name','Item Code', 'Item Group', 'Quantity', 'Value'],
    "BuyerAuditLogReport": ['S_No','dateTimeStamp', 'transactionType', 'transactionId', 'status', 'user', 'userName', 'notes'],
    "CostingReport": ['S No', 'Business Outlet', 'Brand Name', 'Item_Code', 'Item_Name', 'Item_Group', 'Quantity', 'Amount'],
    "priceUpdateMovementReport": ['S No', 'Business Outlet', 'Brand Name', 'Item Code', 'Item Name', 'Item Group', 'Updated Price', 'Effective Start Date', 'Effective End Date'],
    "buyerTransactionSummaryReport": ['Rank', 'Buyer ID', 'Company Name', 'Count', 'Total Amount', 'Month', 'Year', 'Visibility Type'],
    "supplierTransactionSummaryReport": ['Rank', 'Supplier ID', 'Company Name', 'Count', 'Total Amount', 'Month', 'Year', 'Visibility Type'],
    "supplierConsolidatedInvoiceReport": ['S No', 'E-invoice Date', 'PO Number', 'Buyer Code', 'Buyer Name', 'Business Outlet Name ', 'E-Invoice Number', 'Hardcopy Invoice Number', 'PO Delivery Date', 'Goods Receipt Date', 'Delivery Charge', 'Sub-total', 'Discount_Percentage', 'Discount_Amount', 'GST/VAT', 'Grand Total', 'Remarks'],
    "buyerConsolidatedInvoiceReport": ['PO_No', 'Supplier', 'Business_Outlet', 'Invoice Number', 'HardCopy Invoice Number', 'PO_Delivery_date', 'Accepted_Date', 'Total_Delivery_Charge','Sub-total', 'Discount_Percentage', 'Discount_Amount', 'GST/VAT', 'Grand Total', 'Remarks' ],
    "supplierItemPricingReport": ['S_No', 'Item_Name', 'Item_Code', 'UOM', 'Item_Group', 'Buyer', 'Business_Outlet', 'Price','Currency'],
    "supplierStockReport": ['S_No', 'Item_Name', 'Item_Code', 'UOM', 'Packaging', 'Business_Outlet', 'Quantity'],
    "totalPurchaseVolume": ['S_No', 'Business_Outlet', 'Date_Range'],
    "Costing":['S_No', 'Item_Name', 'Item_Group', 'Buyer Item Code', 'Supplier Item_Code', 'UOM', 'Supplier', 'Quantity', 'Value','Business_Outlets','Date_Range'],
    "CostingGroups":['S_No', 'Item_Group','Value','Business_Outlets','Date_Range'],
    "CostingSubGroups":['S_No', 'Sub_Item_Group','Value','Business_Outlets', 'Date_Range'],
    "PurchaseTrends":['S_No','Business_Outlets','Month','Month_Purchase_Value','Quarter', 'Quarter_Purchase_Value', 'Date_Range'],
    "supplierPerformanceHeader":['S_No','Supplier','Date_Range','Total_Quantity_Purchased','Total_Quantity_Received','Success_Rate'],
    "transactionSummaryReportBuyer":['S_No', 'Invoice_No', 'PO_Number', 'Customer_Reference', 'Brand_Name', 'Business_Outlet', 'Payment_Date', 'Invoice_Amount', 'Supplier_Name', 'Card_Number', 'Buyer_BSF_Fee', 'TT_Charge','Epd', 'Total_Amount_payable', 'Payment_Status', 'Remittance_Status', 'Remittance_Date'],
    "bnpltransactionSummaryReportBuyer":['S_No', 'Invoice_No', 'PO_Number', 'Customer_Reference', 'Supplier Name', 'Payment_Date','Brand_Name', 'Invoice_Amount', 'Buyer_BSF_Fee', 'Total_Amount_payable', 'Payment_Status', 'Remittance_Status', 'Remittance_Date'],
    "transactionSummaryReportSupplier":['S_No', 'Invoice_No', 'PO_Number','Customer_Reference', 'Payment_Date','Buyer_Name','Brand_Name', 'Invoice_Amount', 'Supplier_BSF_Fee', 'Epd', 'Remittance_Amount', 'Payment_Status', 'Remittance_Status', 'Remittance_Date', 'PaymentMethod'],
    "transactionSummaryReportNfrnds": ['#', 'Invoice No', 'Fee Id', 'Fee Amount', 'Fee Currency', 'Customer Reference', 'Brand name', 'Payment Date', 'Invoice Amount', 'Supplier Name', 'Payment Status', 'Remittance Status', 'Remarks'],
    "buyerERPCSVData":['itemId','supplierName','supplierItemCode','supplierItemName','UOM','supplierItemGroup','buyerItemCode','buyerItemName','buyerUOM', 'inventoryUOM', 'buyerItemGroup', 'parentItem', 'barcode', 'ignoreBuying','defaultSupplier'],
    "GrVarianceReport": [ 'S/No', 'Item_Name', 'Buyer Item Code', 'Uom', 'Price', 'Supplier', 'PR_No', 'PR_Quantity', 'PO_No', 'PO_Quantity', 'GR_Quantity', 'GR_Rate', 'GR_Date', 'GR_Action_ID', 'Business_Outlet'],
    "priceTrendsRawDataHeader": ['S_No','itemGroup','itemName','itemCode','supplier','price(Per KG)', 'quantity', 'date'],
    "priceTrendsViewHeader": ['S_No', 'date/itemSubGroups'],
    "priceTrendsDayViewHeader": ['itemCode', 'itemName', 'itemGroup', 'supplier', 'price', 'dateOfChange' ],
    "salesInvoiceJurnalCSVHeader": ['*Customer', 'Email', 'BillingAddress', 'ShippingAddress', '*InvoiceDate', '*DueDate', 'ShippingDate', 'ShipVia', 'TrackingNo', 'CustomerRefNo', '*InvoiceNumber', 'Message', 'Memo', '*ProductName', 'Description', '*Quantity', '*UnitPrice', 'ProductDiscountRate(%)', 'InvoiceDiscountRate(%)', 'TaxName', 'TaxRate(%)', 'ShippingFee', '#paid?(yes/no)', '#PaymentMethod', '#PaidToAccountCode', 'Tags', 'WarehouseName'],
    "purchaseInvoiceJurnalCSVHeader": ['*Vendor', 'Email', 'BillingAddress', 'ShippingAddress', '*PurchaseDate', '*DueDate', 'ShippingDate', 'ShipVia', 'TrackingNo', 'VendorRefNo', '*PurchaseNumber', 'Message', 'Memo', '*ProductName', 'Description', '*Quantity', '*UnitPrice', 'ProductDiscountRate(%)', 'PurchaseDiscountRate(%)', 'TaxName', 'TaxRate(%)', 'ShippingFee', '#paid?(yes/no)', '#PaymentMethod', '#PaidFromAccountCode', 'Tags', 'WarehouseName', '#currencyCode'],
    "purchaseOrderListingHeader": ['poNumber', 'businessOutlet', 'supplier', 'poDate', 'itemCode', 'itemName', 'uom', 'poQuantity', 'receivedQuantity', 'unitPrice', 'totalAmount', 'currency', 'receviedDate', 'specificRequest', 'remark','purchaseOrderDate', 'deliveryDate'],
    "statementOfAccountReportCSVHeader":['S_No', 'transactionId', 'transactionTimeStamp', 'supplier', 'buyerName','brandName', 'poNumber', 'eInvoiceOrHardCopyInvoiceNumber', 'invoiceAmount', 'buyerBsfFee', 'buyerBsfGst', 'buyerTotal','supplierBsfFee', 'supplierBsfGst', 'supplierTotal', 'paymentModel', 'epd', 'buyerPaidAmount', 'remittanceAdvice', 'Remittance_Date', 'adminFee', 'giroStatus','issuer','IssuedCountry','IssuedBank'],
    "statementOfAccountReportCSVHeaderTt":['S_No', 'transactionId', 'transactionTimeStamp', 'supplier', 'buyerName','brandName', 'poNumber', 'eInvoiceOrHardCopyInvoiceNumber', 'invoiceAmount', 'buyerBsfFee', 'buyerBsfGst', 'buyerTotal','supplierBsfFee', 'supplierBsfGst', 'supplierTotal', 'paymentModel', 'epd', 'ttCharge', 'buyerPaidAmount', 'remittanceAdvice', 'Remittance_Date', 'adminFee', 'giroStatus','issuer','IssuedCountry','IssuedBank'],
    "nfrndsStatementOfAccountReportCSVHeader":['S_No', 'SGeBiz Transaction Id', 'DDLN Transaction Id', 'Destination Account Number', 'Name of destination account holder','Currency', 'Nominal amount to be transferred', 'Transfer Fee', 'Nominal amount plus transfer fee', 'Description/Notes', 'Recipient Bank Code', 'Recipient  Bank Name', 'Recipient Bank Branch Name', 'Recipient Bank City', 'Receive Email Notification - Y/N', 'Recipient Email Id', 'Payment Status', 'Remarks' ],
    "BNPLstatementOfAccountReportCSVHeader":['S_No', 'transactionId', 'transactionTimeStamp', 'supplier', 'buyerName','brandName','AccountType','poNumber', 'eInvoiceOrHardCopyInvoiceNumber', 'invoiceAmount', 'buyerBsfFee', 'MDR',  'buyerPaidAmount', 'remittanceAmount', 'Remittance_Date', 'Currency', 'giroStatus'],
    "BnplReportCSVHeader":['S_No', 'UEN', 'Company Name','MDR','BSF','Credit Allocated','Credit Utilized','Loan Paid','Credit Balance','Status','Requested Date'],
    "accPacCSVHeader":['Company_Id', 'Transaction_Type', 'Unique_Sys_Id', 'Vendor_Code', 'Vendor_Name', 'Header_Desc', 'Doc_Date', 'Tax', 'Posting_Date', 'Apply_Doc', 'Inv_Amt', 'Doc_Num', 'PO_Num', 'Outlet_Code', 'GL_Code', 'GL_Name', 'Tax_Class', 'Line_Total_inc_GST/VAT', 'Line_Total_exc_GST/VAT', 'Line_GST/VAT','Inv_Tax_Amt'],
    "myobCSVHeader": ['Co./Last Name', 'First Name', 'Purchase #', 'Date', 'Supplier Invoice #', 'Inclusive', 'Memo', 'Description', 'Account Number', 'Amount', 'Inc-Tax Amount', 'Job', 'Tax Code', 'Non-GST/VAT Amount', 'GST/VAT Amount', 'Import Duty Amount', 'Purchase Status', 'Currency Code', 'Exchange Rate', 'Terms - Payment is Due', '           - Discount Days', '           - Balance Due Days', '           - % Discount', 'Amount Paid', 'Category', 'Card ID', 'Record ID'],
    "myobCSVFileHeader":['Co./Last Name','First Name','Addr 1 - Line 1','           - Line 2','           - Line 3','           - Line 4','Permit #','Inclusive','Purchase #','Date','Supplier Invoice #','Ship Via','Delivery Status','Item Number','Quantity','Description','Price','Inc-Tax Price','Discount','Total','Inc-Tax Total','Job','Comment','Journal Memo','Shipping Date','Tax Code','Non-GST Amount','GST Amount','Import Duty Amount','Freight Amount','Inc-Tax Freight Amount','Freight Tax Code','Freight Non-GST Amount','Freight GST Amount','Freight Import Duty Amount','Purchase Status','Currency Code','Exchange Rate','Terms - Payment is Due','           - Discount Days', '           - Balance Due Days','           - % Discount','Amount Paid','Category','Order','Received','Billed','Location ID','Card ID','Record ID'],
    "supplierInvoiceQuickBooksCSVHeader":['Invoice No','Customer','Invoice Date','Due Date','Shipping Date','Ship Via','Tracking no','Terms','Billing Address Line 1','Billing Address Line 2','Billing Address Line 3','Billing Address City','Billing Address Postal Code','Billing Address Country','Billing Address Country Subdivision Code','Shipping Address Line 1','Shipping Address Line 2','Shipping Address Line 3','Shipping Address City','Shipping Address Postal Code','Shipping Address Country','Shipping Address Country Subdivision Code','Memo','Message displayed on invoice','Email','Global Tax Calculation','Shipping','Shipping Tax Code','Discount Amount','Discount Percent','Discount Account','Service Date','Product/Service','Product/Service Description','Product/Service Quantity','Product/Service Rate','Product/Service  Amount','Product/Service Tax Code','Product/Service Tax Amount','Product/Service Class','Deposit','Location','Show Sub Total','Custom Field Value (1)','Custom Field Value (2)','Custom Field Value (3)','Currency Code','Exchange Rate','Print Status','Email Status'],
    "supplierInvoiceMillionExportCSVHeader": ['accno','doc_type','doc_no','seq','doc_date','refno','refno2','refno3','desp','desp2','desp3','desp4','amount','debit','credit','fx_amount','fx_debit','fx_credit','fx_rate','curr_code','taxcode','taxable','fx_taxable','link_seq','billtype','remark1','remark2','batchno','projcode','deptcode','accmgr_id','cheque_no'],
    "warehousePOCSVHeader":['itemCode', 'itemName', 'quantity', 'uom', 'productionDate', 'expiryDate', 'plant', 'remarks', 'productStatus'],
    "supplierSOCSVHeader": ['Line Id', 'Document No.', 'Line No.', 'Ship-to Code','Product', 'Order Qty.', 'Unit of Measure Code', 'Seq No.', 'Shipment Method Code', 'Shipping Mark', 'Gen. Bus. Posting Group', 'CT', 'Qty. Picked(UOM)','Picked UOM','Req. Delivery Date', 'Warehouse', 'Product Status'],
    "supplierSRCSVHeader": ['lineId','documentNo',  'lineNo', 'itemName','itemCode', 'quantity', 'returnedQuantity', 'uom', 'deliveryDate', 'expiryDate', 'productionDate', 'remarks', 'soNo','invoiceNo', 'productStatus'],
    "supplierTOCSVHeader": ['lineId','lineNo','itemCode', 'itemName', 'quantity', 'transferredQuantity', 'receivedQuantity', 'uom', 'remarks', 'containerNumber', 'expiryDate', 'productStatus'],
    "buyerPODataHeader": ['Sr. No.', 'Document No', 'Document Date', 'Container No', 'Warehouse', 'Remarks', 'Rejeced Remarks','Status'],
    "buyerSODataHeader": ['Sr. No.', 'Document No', 'Ship-to Code', 'Product', 'Unit of Measure Code', 'Seq No.','Shipment Method Code', 'Shipping Mark', 'Gen. Bus. Posting Group', 'CT', 'Order Qty.', 'Qty. Picked(UOM)', 'Picked UOM', 'Variance', 'Req. Delivery Date', 'Rejeced Remarks'],
    "buyerSRDataHeader": ['Sr. No.','Document No', 'Document Date', 'Item Code', 'Item Name', 'Production Date', 'Expiry Date', 'Quantity', 'Returned Quantity', 'Warehouse','Variance', 'Status', 'Rejeced Remarks'],
    "buyerTODataHeader": ['Sr. No.', 'Document No', 'Document Date', 'ToWarehouse', 'Item Code', 'Item Name', 'Quantity', 'Transferred', 'Received', 'Variance', 'Status', 'Remarks', 'Rejeced Remarks'],
    "XeroPurchaseInvoiceExportCSVHeader":['ContactName','EmailAddress','POAddressLine1','POAddressLine2','POAddressLine3','POAddressLine4','POCity','PORegion','POPostalCode','POCountry','InvoiceNumber','InvoiceDate','DueDate','Total','InventoryItemCode','Description','Quantity','UnitAmount','AccountCode','TaxType','TaxAmount','TrackingName1','TrackingOption1','TrackingName2','TrackingOption2','Currency'],
    "XeroSalesInvoiceExportCSVHeader":['ContactName','EmailAddress','POAddressLine1','POAddressLine2','POAddressLine3','POAddressLine4','POCity','PORegion','POPostalCode','POCountry','InvoiceNumber','Reference','InvoiceDate','DueDate','Total','InventoryItemCode','Description','Quantity','UnitAmount','AccountCode','TaxType','TaxAmount','TrackingName1','TrackingOption1','TrackingName2','TrackingOption2','Currency','BrandingTheme'],
    "OrderTemplateItemsCSVHeaders": ['Template Name', 'Item Code', 'Item Name', 'Item Group', 'UOM', 'Quantity', 'Delivery Date', 'Special Request'],
    "peppolInvoiceCSVHeader":['Invoice Id','Buyer Organisation Name','Invoice Issue Date','Actual Delivery Date','Payable Amount','Invoice Status'],
    "peppolPurchaseOrderCSVHeader":['PO Id','Supplier Organisation Name','PO Date','Payable Amount','PO Status'],
    "MillionAccountingTemplateHeader": ['accno','doc_type','doc_no','seq','doc_date','refno','refno2','refno3','desp','desp2','desp3','desp4','amount','debit','credit','fx_amount','fx_debit','fx_credit','fx_rate','curr_code','taxcode','taxable','fx_taxable','link_seq','billtype','remark1','remark2','batchno','projcode','deptcode','accmgr_id','cheque_no'],
    "SupplierItemMappingData":['Sr.No',"Ezyprocure Item ID",'Default supplier name','Sub category Name*','Item code*','Item name*','Uom*','Cost*','Min level','Max level','Re order level','Reorder Quantity', 'Default Location Code','Default Warehouse Code','Default Bin Code','Batch management(Yes/No)*'],
    "ForecastMonthy":['S_No','Business_Outlets','Month','Month_Forecast_Value','Date_Range'],
    "ForecastQuarter":['S_No','Business_Outlets','Quarter', 'Quarter_Forecast_Value','Date_Range'],
    "priceTrendsHeader": ['S No', 'Business Outlet', 'Supplier', 'Buyer Item Code', 'Supplier Item Code', 'Item Name', 'Item Group', 'Updated Price', 'Effective Start Date', 'Effective End Date'],
    "PurchaseTrendsMonthly":['S_No','Business_Outlets','Month','Month_Purchase_Value','Date_Range'],
    "PurchaseTrendsQuarter":['S_No','Business_Outlets','Quarter', 'Quarter_Purchase_Value', 'Date_Range'],
    "SAGA50CSVHeader":['Vendor ID', 'Invoice/CM #', 'Credit Memo', 'Date', 'Date Due', 'Accounts Payable Account', 'Number of Distributions', 'Invoice/CM Distribution', 'Apply to Invoice Distribution', 'Description', 'G/L Account', 'Amount'],
    "SupplierSAGA50CSVHeader":['Customer ID', 'Invoice/CM #', 'Credit Memo', 'Date', 'Date Due', 'Accounts Payable Account', 'Number of Distributions', 'Invoice/CM Distribution', 'Apply to Invoice Distribution', 'Description', 'G/L Account', 'Amount', 'Taxtype'],
    "SupplierManageItemsCSVHeaderForInv": ['Sr.No', 'Ezyprocure Item ID', 'Default supplier name', 'Sub category Name*', 'Item code*', 'Item name*', 'Uom*', 'Min level', 'Max level', 'Re order level', 'Reorder Quantity', 'Default Location Code', 'Default Warehouse Code', 'Default Bin Code', 'Batch management(Yes/No)*', 'Col 1', 'Col 2', 'Col 3', 'Col 4', 'Col 5', 'Col 6'],
    "stockTransferReportHeader": ['S No', 'Document No', 'Document Date', 'From Outlet', 'To Outlet', 'Transfer Cost', 'Transfer Quantity', 'Remarks', 'Status'],
    "stockTransferReportHeaderNoCost": ['S No', 'Document No', 'Document Date', 'From Outlet', 'To Outlet', 'Transfer Quantity', 'Remarks', 'Status'],
    "ReplicateItemsHeader" : ['itemCode','itemName','itemDescription','itemKeyword','itemGroup','moq','multiplierFactor','uom','currency','price','packaging','buyerItemCode','buyerItemName','buyerItemGroup','supplierName'],
    //corrently outlet name not needed in csv that 's we commenting code if in future needed outlet name we will uncomment those changes
    // "purchaseRequestApprovalListingHeader": ['S_No', 'Approval Request Number', 'Business Outlet', 'Item Name', 'UOM', 'Quantity', 'Price', 'Delivery Date', 'Supplier Name', 'Requester', 'Total Value', 'Currency'] ,
    "purchaseRequestApprovalListingHeader": ['S_No', 'Approval Request Number', 'Item Name', 'UOM', 'Quantity', 'Price', 'Delivery Date', 'Supplier Name', 'Requester', 'Total Value', 'Currency'],      
    "apCSVHeader":['DocDate', 'DocNo(20)', 'Code(10)', 'CompanyName(100)', 'Description_HDR(200)', 'SEQ', 'ItemCode(30)', 'Description_DTL(200)', 'Quantity', 'UOM(10)', 'UnitPrice', 'Amount', 'Tax(10)', 'TaxInclusive', 'TaxAmt'],
    "grnCSVHeader": ['S.No', 'Purchase Order Number', 'Purchase Order Date', 'Business Outlet', 'Supplier', 'Receiving Status', 'Delivery Date'],
    "buyerSubscriptionReportListingHeader": ['buyerCompanyName', 'brandName','outletName','billingStatus','billingType','subscriptionStartDate','subscriptionEndDate','subscriptionAmount','currency','remarks'],    
    "supplierSubscriptionReportListingHeader": ['supplierCompanyName','billingStatus','billingType','subscriptionStartDate','subscriptionEndDate','subscriptionAmount','currency','remarks']    
  },
  "GEO_MAX_CHAR_LIMIT": {
    "documentCode": 8,
    "orgNo": 8,
    "locNo": 8,
    "purchaserNo": 20,
    "documentDate": 11,
    "accountCode": 16,
    "trade": 16,
    "transportation": 16,
    "shipTo": 128,
    "shippingAddress1": 256,
    "shippingAddress2": 256,
    "shippingAddress3": 256,
    "shippingAddress4": 256,
    "shippingAddressTelNo": 32,
    "shippingAddressFaxNo": 32,
    "projectCode": 16,
    "taxCode": 8,
    "taxRate": 20,
    "currencyCode": 8,
    "currencyRate": 20,
    "tradeTerms": 32,
    "yourReference": 64,
    "ourReference": 64,
    "departmentCode": 16,
    "remarks": 1000,
    "itemNo": 20,
    "itemFlag": 1,
    "stockCode": 32,
    "description": 512,
    "qty": 20,
    "UOM": 8,
    "UOMRatio": 20,
    "stockQty": 20,
    "salesPrice": 20,
    "netPrice": 20,
    "itemRemarks": 1000,
    "acCode": 16
  }
});
