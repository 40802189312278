/**
* @Author: Ajay
* @Date:   03-May-2018 14:38 +05:30
* @Project: Ezyprocure
* @Filename: buyer.bo-user-mapping.service.js
* @Last modified by:   Allan
* @Last modified time: 3-August-2018 15:06 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerBusinessUserMapService', BuyerBusinessUserMapService);

  BuyerBusinessUserMapService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function BuyerBusinessUserMapService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var buyerBusinessUserMapService = {
      isBusinessBuyerUserMapped: isBusinessBuyerUserMapped,
      createBusinessBuyerUserMapping: createBusinessBuyerUserMapping,
      deleteBusinessBuyerUserMapping: deleteBusinessBuyerUserMapping,
      getMappedBusinessOutletsOfLoggedInBuyerUser: getMappedBusinessOutletsOfLoggedInBuyerUser,
      getAllBusinessUserMappings: getAllBusinessUserMappings,
      getAllBusinessUserMappingsForBusiness: getAllBusinessUserMappingsForBusiness,
      getBusinessUserMappingForApproval: getBusinessUserMappingForApproval,
      saveApprovalLevel: saveApprovalLevel,
      deleteAllApprovalLevels: deleteAllApprovalLevels,
      deleteSelectedApprovalLevel:deleteSelectedApprovalLevel,
      getGoodReceiptStatusAllCSV:getGoodReceiptStatusAllCSV
    };

    return buyerBusinessUserMapService;

    /* --  Supplier User Business Outlet Mapping API's -- */

    //Check Business Outlet and Supplier is Mapped or not
    function isBusinessBuyerUserMapped(mapping) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/user/' + mapping.buyerUserId + '/business/' + mapping.businessOutletId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new Business Outlet and Supplier mapping
    function createBusinessBuyerUserMapping(mapping) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/user/' + mapping.buyerUserId + '/business/' + mapping.businessOutletId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // Soft Delete existing Business Outlet and Supplier mapping
    function deleteBusinessBuyerUserMapping(mapping) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/user/' + mapping.buyerUserId + '/business/' + mapping.businessOutletId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getMappedBusinessOutletsOfLoggedInBuyerUser(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'business-outlets/mapped-to-buyer-user',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllBusinessUserMappings(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_USER_MAPPING_RESOURCE,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllBusinessUserMappingsForBusiness(params, buyerBusinessId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_USER_MAPPING_RESOURCE + buyerBusinessId,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getBusinessUserMappingForApproval(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_USER_MAPPING_RESOURCE+'approval-level',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function saveApprovalLevel(buyerBusinessId, businessUserMapping) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_USER_MAPPING_RESOURCE+'approval-level',
        method: "PUT",
        params: {buyerBusinessId: buyerBusinessId},
        data: businessUserMapping
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function deleteAllApprovalLevels(buyerBusinessId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_USER_MAPPING_RESOURCE+'approval-level',
        method: "DELETE",
        params: {buyerBusinessId: buyerBusinessId}
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function deleteSelectedApprovalLevel(id) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_USER_MAPPING_RESOURCE+'approval-level-id',
        method: "DELETE",
        params: {id: id}
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getGoodReceiptStatusAllCSV(grnFilter) {
/*       var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase+ 'api/' + API_CONSTANTS.baseVersion + 'api/good-receipt-status-all-CSV?',
        method: "GET",
        data: grnFilter
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise; */
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'good-receipt-status-all-CSV',{params: grnFilter}).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
