/**
* @Author: Ajay
* @Date:   02-Aug-2017 15:17 +05:30
* @Project: Ezyprocure
* @Filename: buyer.good-receipt-note.consolidated-details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-24T10:13:26+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerGoodReceiptNoteConsolidatedDetailsController', BuyerGoodReceiptNoteConsolidatedDetailsController);

  BuyerGoodReceiptNoteConsolidatedDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'PurchaseOrderService', 'NotificationService', 'UTCDateService', 'PDFExportService', 'EXPORT_CONSTANTS', 'CSVExportService', 'pagingParams', 'GLOBAL_CONSTANTS', 'paginationConstants', 'Auth'];

  function BuyerGoodReceiptNoteConsolidatedDetailsController($scope, $rootScope, $filter, $state, $stateParams, toastr, $mdDialog, PurchaseOrderService, NotificationService, UTCDateService, PDFExportService, EXPORT_CONSTANTS, CSVExportService, pagingParams, GLOBAL_CONSTANTS, paginationConstants, Auth) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.poNumber = $stateParams.poNumber;
    vm.itemGroupId = $stateParams.itemGroupId;
    vm.init = init;
    vm.poFromDate =!$stateParams.poFromDate ? '' : new Date($stateParams.poFromDate);
    vm.poToDate =!$stateParams.poToDate? '': new Date($stateParams.poToDate);
    vm.deliveryFromDate =!$stateParams.deliveryFromDate ? '' : new Date($stateParams.deliveryFromDate);
    vm.deliveryToDate =!$stateParams.deliveryToDate ? '' : new Date($stateParams.deliveryToDate);
    vm.grnFromDate =!$stateParams.grnFromDate ? '' : new Date($stateParams.grnFromDate);
    vm.grnToDate =!$stateParams.grnToDate? '': new Date($stateParams.grnToDate);
    vm.supplierId = $stateParams.supplierId;
    vm.invoiceStatus = $stateParams.invoiceStatus;
    vm.getConsolidatedPurchaseOrderPDF = getConsolidatedPurchaseOrderPDF;
    vm.getGrnConsolidatedCSV = getGrnConsolidatedCSV;
    vm.consolidatedGoodsReceiptNoteHeader = EXPORT_CONSTANTS.CSV.BuyerConsolidatedGoodsReceiptNoteHeader;
    vm.selectedOutletId = $stateParams.selectedOutletId;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.getConsolidatedGrnMetaData = getConsolidatedGrnMetaData;
    vm.query = {
      limit: paginationConstants.consolidatedItemsPerPage,
      page: 1
    };
    vm.showMore = showMore;
    vm.consolidatedPODetails = [];
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.inclusiveOfTax = GLOBAL_CONSTANTS.inclusiveOfTax;
    vm.showAll = showAll;

    /**
    * [init loads initilally]
    */
    function init(){
      if(vm.accessForPriceDisplay)
        vm.consolidatedGoodsReceiptNoteHeader = EXPORT_CONSTANTS.CSV.BuyerConsolidatedGoodsReceiptNoteHeader;
      else {
        vm.consolidatedGoodsReceiptNoteHeader = _.without(EXPORT_CONSTANTS.CSV.BuyerConsolidatedGoodsReceiptNoteHeader, 'unit_price', 'PO_amount', 'accepted_amount');
      }
      loadAll();
      vm.getConsolidatedGrnMetaData();
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [loadAll Gets Purchase Order Details]
    */
    function loadAll() {
      vm.purchaseRequestObject = PurchaseOrderService.getPurchaseRequestParams();
      var page = vm.query.limit === vm.totalItems? null: vm.query.page - 1;
      vm.itemGroupId = $stateParams.itemGroupId;
      vm.poFromDate = !vm.purchaseRequestObject.poFromDate ? '' : new Date(vm.purchaseRequestObject.poFromDate);
      vm.poToDate = !vm.purchaseRequestObject.poToDate ? '' : new Date(vm.purchaseRequestObject.poToDate);
      vm.deliveryFromDate = !vm.purchaseRequestObject.deliveryFromDate ? '' : new Date(vm.purchaseRequestObject.deliveryFromDate);
      vm.deliveryToDate = !vm.purchaseRequestObject.deliveryToDate ? '' : new Date(vm.purchaseRequestObject.deliveryToDate);
      vm.grnFromDate = !vm.purchaseRequestObject.grnFromDate ? '' : new Date(vm.purchaseRequestObject.grnFromDate);
      vm.grnToDate = !vm.purchaseRequestObject.grnToDate ? '' : new Date(vm.purchaseRequestObject.grnToDate);
      PurchaseOrderService.getGRNByPONumberAll({
        page: page,
        size: vm.query.limit,
        sort: sort(),
        invoiceStatus: vm.purchaseRequestObject.invoiceStatus,
        businessOutletId: !vm.purchaseRequestObject.businessOutletId ? null:vm.purchaseRequestObject.businessOutletId,
        businessOutletName: !vm.purchaseRequestObject.selectedOutlet ? '':vm.purchaseRequestObject.selectedOutlet.businessOutletName,
        poFromDate: !vm.poFromDate ? '' : UTCDateService.utcFromDate(vm.poFromDate),
        poToDate: !vm.poToDate ? '' : UTCDateService.utcToDate(vm.poToDate),
        deliveryFromDate: !vm.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.deliveryFromDate),
        deliveryToDate: !vm.deliveryToDate ? '' : UTCDateService.utcToDate(vm.deliveryToDate),
        grnFromDate : !vm.grnFromDate ? '' : UTCDateService.utcFromDate(vm.grnFromDate),
        grnToDate : !vm.grnToDate ? '' : UTCDateService.utcToDate(vm.grnToDate),
        supplierId: !vm.purchaseRequestObject.supplierId?null:vm.purchaseRequestObject.supplierId,
        itemGroupId: vm.itemGroupId ? vm.itemGroupId:null,
        poNumber: vm.purchaseRequestObject.poNumber ? vm.purchaseRequestObject.poNumber:'',
        buyerIdList: null
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.hasMore = _.gt(parseInt(vm.totalItems), (vm.query.page  * vm.query.limit));
        vm.consolidatedPODetails = vm.consolidatedPODetails.concat(response.data);
        vm.pageDetails = "Showing " + (vm.consolidatedPODetails.length === 0 ? "0" : "1") + " to " + $filter('number')(vm.consolidatedPODetails.length) + " of " + $filter('number')(vm.totalItems) + " entries";
      },function(error){
        NotificationService.error({
          title: 'Consolidated Goods Receipt Notes Details',
          error: error
        });
      });
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    function getConsolidatedPurchaseOrderPDF() {
      PDFExportService.getConsolidatedGrnPDF({
        poFromDate: !vm.poFromDate ? '' : UTCDateService.utcFromDate(vm.poFromDate),
        poToDate: !vm.poToDate ? '' : UTCDateService.utcToDate(vm.poToDate),
        deliveryFromDate: !vm.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.deliveryFromDate),
        deliveryToDate: !vm.deliveryToDate ? '' : UTCDateService.utcToDate(vm.deliveryToDate),
        grnFromDate : !vm.grnFromDate ? '' : UTCDateService.utcFromDate(vm.grnFromDate),
        grnToDate : !vm.grnToDate ? '' : UTCDateService.utcToDate(vm.grnToDate),
        reportType: "PO",
        supplierId: vm.supplierId ? vm.supplierId: '',
        invoiceStatus: vm.invoiceStatus,
        businessOutletId: !vm.selectedOutletId ? '':vm.selectedOutletId,
        businessOutletName: !vm.selectedOutlet ? '':vm.selectedOutlet.businessOutletName,
        itemGroupId: vm.itemGroupId ? vm.itemGroupId:'',
        poNumber: vm.poNumber ? vm.poNumber:'',
        paymentStatus	: '',
        buyerIdList: '',
      })
      .then(function(response){
        downloadURI(response.data.message, "PDF_" + vm.purchaseOrderNumber);
      },function(error) {
        toastr.error('Please try again!', 'PDF Export');
      });
    }

    function getGrnConsolidatedCSV() {
      return CSVExportService.grnConsolidatedCSV(vm.consolidatedPODetails, 'buyer');
    }

    /**
     * [getConsolidatedGrnMetaData Gets Consolidated Goods Receipt Notes]
     */
    function getConsolidatedGrnMetaData() { 
      var filterData= JSON.parse(localStorage.getItem('good-receipt-note-filter'));
      if(filterData.businessOutletId.length>0)
      {
        var selectedOutletId=filterData.businessOutletId[0];
      }
      
      PurchaseOrderService.getConsolidatedGrnMetaData({
        poFromDate: !vm.poFromDate ? '' : UTCDateService.utcFromDate(vm.poFromDate),
        poToDate: !vm.poToDate ? '' : UTCDateService.utcToDate(vm.poToDate),
        deliveryFromDate: !vm.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.deliveryFromDate),
        deliveryToDate: !vm.deliveryToDate ? '' : UTCDateService.utcToDate(vm.deliveryToDate),
        grnFromDate : !vm.grnFromDate ? '' : UTCDateService.utcFromDate(vm.grnFromDate),
        grnToDate : !vm.grnToDate ? '' : UTCDateService.utcToDate(vm.grnToDate),
        supplier:{
          'id': vm.supplierId
        },
        reportType: "PO",
        purchaseOrderStatus: vm.invoiceStatus,
        businessOutletId: !vm.selectedOutletId ? '':selectedOutletId,
        itemGroup:{
          'id': vm.itemGroupId
        },
        poNumber: vm.poNumber ? vm.poNumber:'',
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }).then(function(response){
        vm.consolidatedCurrency = response.data;
      },function(error){
        NotificationService.error({
          title: 'global.consolidatedGoodsReceiptNotesDetails',
          error: error
        });
      });
    }

    function showMore() {
      vm.query.page += 1;
      loadAll();
    }

    function showAll() {
      vm.query.limit = vm.totalItems;
      vm.consolidatedPODetails = [];
      loadAll();
    }

  }
})();
