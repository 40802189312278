/**
* @Author: hareesh
* @Date:   13-Aug-2017 09:11 +05:30
* @Project: Ezyprocure
* @Filename: supplier.top-buyers.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:41 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierTopBuyersController', SupplierTopBuyersController);

  SupplierTopBuyersController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'pagingParams', '$stateParams', 'NotificationService', '$mdDialog', 'UTCDateService','SupplierPriceUpdateHistoryService', 'ItemGroupService', 'paginationConstants', 'CSVExportService', 'EXPORT_CONSTANTS','BusinessReportService','SupplierDataService','BuyerSupplierMappingService','BuyerBusinessService'];

  function SupplierTopBuyersController($scope, $rootScope, $q, $filter, $state, pagingParams, $stateParams, NotificationService, $mdDialog, UTCDateService, SupplierPriceUpdateHistoryService, ItemGroupService, paginationConstants, CSVExportService, EXPORT_CONSTANTS,BusinessReportService,SupplierDataService,BuyerSupplierMappingService,BuyerBusinessService  ) {
    var vm = this;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.itemGroupId = null ;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.pieChart=pieChart;
    vm.loadItemGroups =  loadItemGroups;
    //Header
    vm.topBuyersHeader = EXPORT_CONSTANTS.CSV.TopBuyersReport;
    //Function Declaration
    vm.getTopBuyersCSVData = getTopBuyersCSVData;
    vm.resetFilters = resetFilters;
    vm.loadAllTopBuyers = loadAllTopBuyers;
    vm.cancel = cancel;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.filter = {};
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.resetCharts = resetCharts;
    vm.showbarChart = false;
    //Getting CSV DATA
    function getTopBuyersCSVData(){
      var deferred = $q.defer();
      if (!vm.priceUpdateItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getTopBuyersCSV(vm.priceUpdateItems);
    }
    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    function pieChart() {
      var priceUpdateItem=vm.priceUpdateItems;
      var arr;
      var data = new google.visualization.DataTable();
      data.addColumn('string', 'Buyer');
      data.addColumn('number', 'values');
      for (priceUpdateItem in vm.priceUpdateItems){
        arr = (vm.priceUpdateItems[priceUpdateItem]);
        data.addRow([arr[1],arr[7]]);
      }
      if(data.getNumberOfRows()==0){}
      else {
        var options = {
          fontName: 'Roboto',
          height: 300,
          width: 500,
          chartArea: {
            left: 50,
            width: '90%',
            height: '90%'
          }
        };
        var chart = new google.visualization.PieChart(document.getElementById('Piechart_material1'));
        chart.draw(data, options);
      }
    }
    google.charts.load('current', {packages: ['corechart']});
    google.charts.setOnLoadCallback(pieChart);
    /**
    * [loadAll loads all price update history]
    */
    function loadAll() {
      SupplierPriceUpdateHistoryService.getTopBuyers({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        query: !vm.buyer ? '' : vm.buyer,
        itemName: !vm.itemName ? '' : vm.itemName,
        supplierId: !vm.supplierId ? '' : vm.supplierId,
        itemGroupId: !vm.itemGroupId ? '' : vm.itemGroupId
      }).then(function(response){
        vm.priceUpdateItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
        vm.pieChart=pieChart();
      },function(error){
      });
    }
    function resetFilters() {
      vm.toDate = vm.fromDate = vm.todaysDate;
      vm.itemGroupId = vm.itemName = vm.priceUpdateItems = null;
      
      // Reset searchBuyerTerm if undefined or null
      vm.searchBuyerTerm = vm.searchBuyerTerm || {};
      vm.searchBuyerTerm.companyName = '';
      vm.filter.buyerIdList = vm.filter.buyerBusinessIdList = null;
      
      // Reset businessOutletSearchTerm if undefined or null
      vm.businessOutletSearchTerm = vm.businessOutletSearchTerm || {};
      vm.businessOutletSearchTerm.businessOutletName = '';
  }
  

    //Get all buyers mapped to the supplier
    function getAllSupplierMappedBuyers() {
      vm.filter.businessIdList = [];
      vm.businessOutlets = [];
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if (vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function (response) {
        vm.buyers = response.data;
      }, function (error) {
      });
    }

    //get all business outlets of the selected buyer
    function getAllBuyersBusinessOutlets() {
      if (!vm.filter.buyerIdList) {
        return false;
      }
      if (vm.filter.businessIdList > 0) {
        return false;
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        groupBy: 'buyer'
      };
      BuyerBusinessService.getAllBuyersBusinessOutlets(vm.filter.buyerIdList, params).then(function (response) {
        vm.buyerBusinessOutlets = response.data;
        vm.businessOutlets = [];
        _.forEach(vm.buyerBusinessOutlets, function (buyer) {
          _.forEach(buyer.businessDTOList, function (outlets) {
            vm.businessOutlets.push(outlets);
          });
        });
      }, function (error) {
      });
    }

    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS':
          vm.filter.buyerIdList = _.map(vm.buyers, _.property('id'));
          break;
        case 'BUSINESSOUTLETS':
          vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('businessId'));
          break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS':
          vm.filter.buyerIdList = [];
          break;
        case 'BUSINESSOUTLETS':
          vm.filter.buyerBusinessIdList = [];
        break;
      }
    }

    /**
*topBuyersPieChart views a pie chart representing the top suppliers and respective percentage
*/
    function topBuyersPieChart() {
      vm.options = {
        chart: {
          type: 'pieChart',
          height: 300,
          x: function (d) { return d.key; },
          y: function (d) { return d.value; },
          showLabels: false,
          duration: 500,
          labelThreshold: 0.01,
          labelSunbeamLayout: true,
          legend: {
            margin: {
              top: 5,
              right: 35,
              bottom: 5,
              left: 0
            }
          } ,
          pie: {
            dispatch: {
              elementClick: function (e, d3) {
                handlePieClick(e);
              }
            }
          }
        }
      };
      vm.data = [];

      vm.topBuyerItems.forEach(function (element) {
        var item = {};
        item.key = element.buyer;
        item.value = element.percentage;
        item.buyerId = element.buyerId;
        item.buyer = element.buyer;
        vm.data.push(item);
      });

    }

    /**
* [loadAll loads all top buyers]
*/
    function loadAllTopBuyers() {
      vm.duplicateTopPurchasesByItemGroup = [];
      BusinessReportService.getTopBuyers({
        fromDate:  !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        businessOutletIds: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList,
        buyerIds: !vm.filter.buyerIdList ? [] : vm.filter.buyerIdList
      },
        {
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort()
        }).then(function (response) {
          vm.topBuyerItems = response.data.topBuyers;
          vm.topPurchasesByItemGroup = response.data.topPurchasesByItemGroup;
          vm.duplicateTopPurchasesByItemGroup = angular.copy(vm.topPurchasesByItemGroup);
          vm.totalBuyerItems = response.headers('X-Total-Count');
          vm.topBuyersPieChart = topBuyersPieChart();
          vm.barChart = barChart();
          vm.showbarChart = false;
          vm.buyerSel = '';
        }, function (error) {
          NotificationService.error({
            title: "Failed to retrieve the top buyers report!",
            message: error
          });
        });
    }

    function handleBarClick(e) {
      loadBuyersByItemGroupsId(e.data);
      $scope.$apply();
    }

    function cancel() {
      vm.showbarChart = !vm.showbarChart;
      if (vm.showbarChart === true) {
        vm.showbarChart = false;
      }
    }


    

    function barChart() {
      vm.barOptions = {
        chart: {
          type: 'discreteBarChart',
          height: 400,
          margin: {
            "top": 20,
            "right": 20,
            "bottom": 50,  // Increased bottom margin for long labels
            "left": 80  // Increased left margin for long labels
          },
          x: function (d) { return d.label; },
          y: function (d) { return d.value + (1e-10); },
          showValues: true,
          valueFormat: function (d) {
            return d3.format(',.2f')(d);
          },
          duration: 500,
          xAxis: {
            axisLabel: 'Item Groups'
          },
          yAxis: {
            axisLabel: 'Amount',
            axisLabelDistance: -10,
            showMaxMin: true,
            staggerLabels: true,
            wrapLabels: true,
            rotateYLabel: false,
            rotateLabels: 45,
            tickFormat: function (d) {
              if (d >= 1000000) {
                return d3.format('.2s')(d); // Format with suffix if value is above one million
              } else {
                return d3.format(',.2f')(d); // Format as a regular number with two decimal places
              }
            }
          },
          discretebar: {
            dispatch: {
              elementClick: function (e) {
                handleBarClick(e);
              }
            }
          }
        },
        title: {   
          enable: true,
          text: vm.buyerSel===undefined||vm.buyerSel===''? "Top Item Groups":vm.buyerSel+' - '+"Top Item Groups"
        
        }
      };

      vm.barData = [];
      var item = {
        key: "Top Item Groups",
        values: []
      };

      vm.topPurchasesByItemGroup.forEach(function (element) {
        var valueItem = {
          label: element.itemGroupName,
          value: element.amount,
          id: element.itemGroupId
        };
        item.values.push(valueItem);
      });
      vm.barData.push(item);
    }

    /**
    * [loadAll loads all top suppliers itemgroups vs amount]
    */
    function loadBuyersByItemGroupsId(d) {
      BusinessReportService.loadBuyersByItemGroupsId({
        fromDate:  !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        businessOutletIds: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList,
        buyerIds: !vm.filter.buyerIdList ? [] : vm.filter.buyerIdList,
        itemGroupIds:  !d ? [] : [d.id],
        query: ''
      },
        {
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort()
        }).then(function (response) {
          vm.topBuyersByItemGroupId = response.data;
          vm.topBuyersByItemGroupIdBarChart = topBuyersByItemGroupIdBarChart(d);
        }, function (error) {
          NotificationService.error({
            title: "Failed to retrieve the top buyers report!",
            message: error
          });
        });
    }

    function topBuyersByItemGroupIdBarChart(e) {
      vm.buyersBarOptions = {
        chart: {
          type: 'multiBarHorizontalChart',
          height: 300,
          margin: {
            top: 20,
            right: 100,
            bottom: 50,
            left: 200
          },
          x: function (d) { return d.label; },
          y: function (d) { return d.value; },
          showControls: false,
          showValues: true,
          duration: 500,
          xAxis: {
            showMaxMin: false,
            margin: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 100
            },
            width: 100,
            axisLabelDistance: 10,
            tickFormat: function (d) {
              if (typeof d === 'string' && d.length > 30) {
                return d.substring(0, 30) + '...'; // Truncate and add ellipsis for strings longer than 30 characters
              } else {
                return d;
              }
            } ,
            tickValues: function (d) {
              return _.map(d[0].values, function(f) {
                return f.label;
              }); // Ensures that the full label is used in tooltips
            }
          },
          yAxis: {
            axisLabel: 'Amount',
            staggerLabels: false,
            tickFormat: function (d) {
              if (d >= 1000000) {
                return d3.format('.2s')(d); // Format with suffix if value is above one million
              } else {
                return d3.format(',.2f')(d); // Format as a regular number with two decimal places
              }
            }
          },
          tooltip: {
            contentGenerator: function (d) {
              // This controls the content of the tooltip
              var color = d.color || d3.scale.category10()(d.index);
              var tooltipContent = '<div style="display: flex; align-items: center; margin: 5px;">' +
                '<span style="width: 14px; height: 14px; background-color: ' + color + '; display: inline-block;"></span>' +
                '<span style="margin-left: 10px;">' + d.data.label + '</span>' +
                '<span style="font-size: 12px; color: #333; margin-left: 10px;">' +
                '<b>' + d3.format(',.2f')(d.data.value) + '</b>' +
                '</span>' +
                '</div>';
              return tooltipContent;
            }
          },
          multibar: {
            width: 960,
            height: 500,
            forceY: [0],
            stacked: false,
            showValues: false,
            valuePadding: 60,
            groupSpacing: 0.1,
            barColor: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"]  // Add colors here

          },
        },
        title: {
          enable: true,
          text: e.label
        }
      };

      vm.buyersBarData = [];
      var item = {
        key: "Top Buyers",
        values: []
      };

      vm.topBuyersByItemGroupId.forEach(function (element) {
        var valueItem = {
          label: element.buyerName,
          value: element.amount
        };
        item.values.push(valueItem);
      });
      vm.buyersBarData.push(item);
      vm.showbarChart = true;
    }
    
    function handlePieClick(e) {
      vm.buyerSel = '';
      vm.showbarChart = false;
      loadItemGroupsByBuyerId(e.data.buyerId);            
      vm.buyerSel = e.data.buyer;            
      $scope.$apply();
    }

    /**
    * [loadAll loads all top buyers itemgroups vs amount]
    */
    function loadItemGroupsByBuyerId(d) {
      var params = {
        fromDate: vm.fromDate,
        toDate: vm.toDate,
        businessOutletIds: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList,
        buyerIds: !d ? [] : [d],
        mainItemGroupIdList: !vm.filter.mainItemGroupIdList ? [] : vm.filter.mainItemGroupIdList,
        itemGroupIdList: !vm.filter.itemGroupIdList ? [] : vm.filter.itemGroupIdList,
        query: ''
      };
    
      var pageParams = {
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      };
    
      BusinessReportService.loadItemGroupsByBuyerId(params, pageParams)
        .then(function (response) {
          vm.topPurchasesByItemGroup = response.data;
          vm.barChart = barChart();
        })
        .catch(function (error) {
          NotificationService.error({
            title: "Failed to retrieve the top suppliers report!",
            message: error
          });
        });
    }

    function resetCharts(){
      vm.showbarChart = false;
      vm.buyerSel = '';
      vm.topPurchasesByItemGroup = vm.duplicateTopPurchasesByItemGroup;
      vm.topBuyersPieChart = topBuyersPieChart();
      vm.barChart = barChart();      
    }

  }
})();
