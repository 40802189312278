/*
 * @Author: Lovin Chacko 
 * @Date: 2021-03-31 12:28:50 
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2021-05-06 01:13:10
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('ManageBlanketOrderController', ManageBlanketOrderController);

    ManageBlanketOrderController.$inject = ['$scope', '$rootScope', '$state', '$q', 'NotificationService', '$mdDialog', 'Auth', 'paginationConstants', 'SupplierDataService', 'BuyerSupplierMappingService', 'BuyerDataService', 'BlanketOrderService', 'GLOBAL_CONSTANTS', 'EXPORT_CONSTANTS', 'CSVExportService', 'pagingParams','$filter'];


    function ManageBlanketOrderController($scope, $rootScope, $state, $q, NotificationService, $mdDialog, Auth, paginationConstants, SupplierDataService, BuyerSupplierMappingService, BuyerDataService, BlanketOrderService, GLOBAL_CONSTANTS, EXPORT_CONSTANTS, CSVExportService, pagingParams,$filter) {
      var vm = this;
      vm.filter={};
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBuyerMappedSuppliers = getAllBuyerMappedSuppliers;
    vm.userRole = Auth.getUserRole();
    vm.getBlanketOrders = getBlanketOrders;
    vm.getBlanketOrderById = getBlanketOrderById;
    vm.init = init;
    vm.status = [null, 'ACCEPTED', 'PENDING', 'APPROVED'];
    vm.deleteBlanketOrderById = deleteBlanketOrderById;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.getBlanketOrderCSVDataById = getBlanketOrderCSVDataById;
    vm.blanketOrderItemsHeaders = EXPORT_CONSTANTS.CSV.blanketOrderItemsHeaders;
    vm.getBlanketOrderpagination = getBlanketOrderpagination;
    vm.approveBlanketOrderRequest = approveBlanketOrderRequest;

    //init
    function init() {
      if (!Auth.hasPermission('accessForManageBlanketOrder'))
        $state.go(vm.userRole.pretty + '.dashboard');
      vm.getBlanketOrders();
    }

    //Get all blanket orders for the buyer or supplier
    function getBlanketOrders() {
      var query='?page=0&size='+paginationConstants.maxLimit;
      BlanketOrderService.getAllBlanketOrders(vm.filter,query).then(function (response) {
         vm.blanketOrdersList = response;
         vm.blanketOrdersListFilter=JSON.parse(JSON.stringify(vm.blanketOrdersList));
         if(vm.blanketOrderNumber){
           vm.blanketOrdersList= $filter("search")(vm.blanketOrdersListFilter,vm.blanketOrderNumber, "blanketOrderNumber");
         }
        if (vm.blanketOrdersList.length != 0)
          getBlanketOrderById(vm.blanketOrdersList[0].blanketOrderId);
      },
        function (error) {
          NotificationService.error({
            title: 'global.manageBlanketOrder',
            error: error
          });
        });
    }

    //sort
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    //Get blanket order items by blanket order id
    function getBlanketOrderById(id) {
      BlanketOrderService.getBlanketOrderById(id, {
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
      }).then(function (response) {
        vm.id = id;
        vm.blanketOrderItem = response.data;
        vm.totalItems = vm.blanketOrderItem.blanketOrderItemsMappingPage.totalElements;
      }, function (error) {
        NotificationService.error({
          title: 'global.manageBlanketOrder',
          error: error
        });
      });
    }

    //pagination
    function getBlanketOrderpagination() {
      BlanketOrderService.getBlanketOrderById(vm.id, {
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
      }).then(function (response) {
        vm.blanketOrderItem = response.data;
        vm.totalItems = vm.blanketOrderItem.blanketOrderItemsMappingPage.totalElements;
      },
        function (error) {
          NotificationService.error({
            title: 'global.manageBlanketOrder',
            error: error
          });
        });
    }

    //Get all buyers mapped to the supplier
    function getAllSupplierMappedBuyers() {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if (vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function (response) {
        vm.buyers = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'global.manageBlanketOrder',
          error: error
        });
      });
    }

    // get all suppliers mapped to the buyer
    function getAllBuyerMappedSuppliers() {
      if (vm.suppliers)
        return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit
      };
      var buyer = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BuyerSupplierMappingService.getAllBuyerMappedSuppliers(buyer, params).then(function (response) {
        vm.suppliers = _.filter(response.data, function (supplier) {
          return supplier.visibilityType != 'ADHOC_OFF_BOARD_SUPPLIER';
        });

      }, function (error) {
        NotificationService.error({
          title: 'global.manageBlanketOrder',
          error: error
        });
      });
    }

    //show confirmation modal for deleting the template
    vm.showConfirm = function (ev, id) {
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete this blanket order?')
        .textContent('This action cannot be reverted back.')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');
      $mdDialog.show(confirm).then(function () {
        vm.deleteBlanketOrderById(id);
      }, function () { });
    }

    //delete blanket order by id
    function deleteBlanketOrderById(id) {
      BlanketOrderService.deleteBlanketOrderById(id).then(function (response) {
        NotificationService.success({
          title: 'Blanket Order',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.getBlanketOrders();
      }, function (error) {
        NotificationService.error({
          title: 'Blanket Order',
          error: error
        });
      });
    }

    //Export blanket order
    function getBlanketOrderCSVDataById(id) {
      var deferred = $q.defer();
      if (!vm.blanketOrderItem.blanketOrderItemsMappingPage.content) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.PLEASE_SELECT_ANOTHER_ORDER'
        });
        return deferred.promise;
      }
      return CSVExportService.getBlanketOrderCSVDataById(vm.blanketOrderItem.blanketOrderItemsMappingPage, vm.blanketOrderItem.blanketOrder);
    }

    //Approve blanket order 
    function approveBlanketOrderRequest(id) {
      BlanketOrderService.approveBlanketOrder(id).then(function (response) {
        NotificationService.success({
          title: 'Blanket order',
          message: 'alertMessage.APPROVED_SUCCESSFULLY'
        });
        vm.init();
      }, function (error) {
        NotificationService.error({
          title: 'global.manageBlanketOrder',
          error: error
        });
      });
    }

  }
})();